<template>
  <page-loading v-if="state.pageLoading"></page-loading>

  <div v-if="!state.pageLoading" class="page-container-new">
    <page-header
      title="콘텐츠 미리보기"
      :back-button="true"
      :is-back-btn-action="true"
      @backBtnAction="actions.goToContents()"
    >
      <template #headerRightSide>
        <button-basic
          text="콘텐츠 수정"
          padding="8px 16px"
          @action="actions.goToContentEdit()"
        ></button-basic>
      </template>
    </page-header>

    <card-basic>
      <div class="content-header">
        <img :src="state.content.featuredImage" alt="" class="thumbnail" />

        <div class="text-info">
          <h2>
            {{ state.content.title }}
          </h2>

          <span>
            공개일자
            {{ moment(state.content.publishedAt).format("YYYY-MM-DD HH:mm") }}
          </span>
          /
          <span>
            생성일
            {{ moment(state.content.createdAt).format("YYYY-MM-DD HH:mm") }}
          </span>
        </div>
      </div>

      <hr />

      <!-- Descriptions -->
      <div>
        <div v-if="state.content.description" class="content-desc">
          <T :data="state.content.description"></T>
        </div>

        <div
          v-if="
            state.content.channelCode === 'youtube' && state.content.identifier
          "
        >
          <embed-player
            :channel-code="state.content.channelCode"
            :identifier="state.content.identifier"
          ></embed-player>
        </div>

        <div
          v-if="state.content.text && state.content.text !== 'null'"
          class="contents-text"
        >
          <div v-html="state.content.textHtml"></div>
        </div>
        <div v-if="state.content.url && state.content.url !== 'null'">
          <button-basic
            class="original-btn"
            text="원본 보러가기"
            bg-color="transparent"
            color="#8E1EFF"
            @action="Helper.goToExternalUrl(state.content.url)"
          >
            <template #icon>
              <arrow-icon
                stroke-size="normal"
                fill-color="#8E1EFF"
              ></arrow-icon>
            </template>
          </button-basic>
        </div>

        <template v-if="state.content.attachments.length > 0">
          <hr />
          <div v-if="state.content.attachments.length > 0">
            <h3>첨부파일</h3>
            <attachment-file-link
              v-for="attachment in state.content.attachments"
              :key="attachment.resourceId"
              class="my-2"
              :show-delete-btn="false"
              :attachment="attachment"
            ></attachment-file-link>
          </div>
        </template>

        <hr />

        <div class="comment-wrapper">
          <h3>댓글</h3>
          <input-comment
            class="input-comment"
            text-size="s2"
            @action="
              (reply) => {
                actions.postContentComment({
                  resourceId: state.content.resourceId,
                  reply: reply,
                });
              }
            "
          ></input-comment>
          <infinite-scroll
            :no-result="state.noResultComments"
            :message="state.noCommentsMessage"
            @infiniteScroll="!state.infiniteLoading && actions.getComments()"
          >
            <comment-component
              v-for="comment in state.comments"
              :key="`comment-${comment.resourceId}`"
              parent-model="content"
              child-parent-model="comment"
              :comment="comment"
            ></comment-component>
          </infinite-scroll>
        </div>
      </div>
    </card-basic>
  </div>
  <teleport :disabled="true">
    <report-comment-modal
      v-if="state.showCommentReportModal"
      model="comments"
      :model-resource-id="state.selectedCommentResourceId"
      @hideModal="actions.closeReportCommentModal()"
      @reportSuccess="
        [actions.successCommentReport(), actions.closeReportCommentModal()]
      "
    ></report-comment-modal>
    <success-comment-report-modal
      v-if="state.showSuccessCommentReportModal"
      @closeModal="actions.closeSuccessCommentReportModal()"
    ></success-comment-report-modal>
  </teleport>
</template>

<script>
import { useStore } from "vuex";
import { computed, onBeforeMount, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment-timezone";
import T from "@/components/web/I18n_Func/T";
import InfiniteScroll from "infinite-loading-vue3";
import Helper from "@/helper";
import PageLoading from "../../../components/console/loadings/PageLoading";
import EmbedPlayer from "../../../components/console/EmbedPlayer/EmbedPlayer";
import AttachmentFileLink from "../../../components/console/files/AttachmentFileLink";
import CommentComponent from "./CommentComponent/CommentComponent";
import InputComment from "./InputComment/InputComment";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import PageHeader from "../../../components/console/headers/PageHeader";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import ReportCommentModal from "./ReportCommentModal/ReportCommentModal.vue";
import SuccessCommentReportModal from "./SuccessCommentReportModal/SuccessCommentReportModal.vue";

export default {
  name: "ContentShow",
  components: {
    SuccessCommentReportModal,
    ReportCommentModal,
    CardBasic,
    ArrowIcon,
    PageHeader,
    ButtonBasic,
    InputComment,
    CommentComponent,
    AttachmentFileLink,
    EmbedPlayer,
    PageLoading,
    InfiniteScroll,
    T,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const typeList = [
      {
        id: "type1",
        value: "1",
        text: "article",
        iconClass: "far fa-newspaper",
      },
      {
        id: "type3",
        value: "3",
        text: "video",
        iconClass: "fab fa-youtube",
      },
      {
        id: "type5",
        value: "5",
        text: "link",
        iconClass: "fas fa-link",
      },
    ];

    const contentResourceId =
      route.path.split("/")[route.path.split("/").length - 1];

    const backRouter = router.options.history.state.back;

    const state = reactive({
      user: store.getters["auth/user"],
      content: computed(() => {
        return store.state.contents.content;
      }),
      comments: computed(() => {
        return store.state.comments.contentComments;
      }),
      pageLoading: false,
      contentTypeText: computed(() => {
        return typeList.find((item) => {
          return parseInt(item.value) === state.content.type;
        }).text;
      }),
      infiniteLoading: true,
      commentPage: 1,
      noResultComments: true,
      noCommentsMessage: "",
      getCommentsLoading: false,
      selectDropdown: "",
    });

    onBeforeMount(() => {
      let completeGetContent = false;
      let completeGetContentComments = false;

      const params = {
        resourceId: contentResourceId,
        model: "contents",
        page: state.commentPage,
      };
      store.dispatch("comments/getContentComments", params).then(() => {
        completeGetContentComments = true;
        state.commentPage++;

        if (completeGetContent && completeGetContentComments) {
          state.pageLoading = false;
        }
      });

      if (state.content.resourceId !== contentResourceId) {
        state.pageLoading = true;
        store
          .dispatch("contents/getContent", { contentResourceId })
          .then(() => {
            completeGetContent = true;
            if (completeGetContent && completeGetContentComments) {
              state.pageLoading = false;
            }
          });
      }
    });

    const goToEntContentsPath = (page) => {
      if (page === "edit") {
        console.log("e");
        router.push({
          path: `/console/ent/contents/${contentResourceId}/edit`,
        });
      } else {
        router.push({
          name: "console.ent.contents",
        });
      }
    };

    const actions = {
      showAllComments: () => {
        state.getCommentsLoading = true;
        // 인피니티 스크롤을 동작시키기 위함.
        state.infiniteLoading = false;
        window.scrollBy(0, -1);
        window.scrollBy(0, document.body.scrollHeight);
      },
      getComments: async () => {
        state.infiniteLoading = true;

        const params = {
          resourceId: contentResourceId,
          model: "contents",
          page: state.commentPage,
        };
        await store
          .dispatch("comments/getContentComments", params)
          .then((res) => {
            if (res.data.data.length) {
              state.commentPage++;
              state.infiniteLoading = false;
            } else {
              state.noResultComments = true;
              state.noCommentsMessage = "No result";
            }
          });
      },
      postContentComment: (param) => {
        store.dispatch("comments/postContentComment", {
          resourceId: param.resourceId,
          content: param.reply,
          model: "contents",
        });
      },
      goToContentEdit: () => {
        if (backRouter.includes("/console/ent/contents")) {
          goToEntContentsPath("edit");
        } else {
          router.push({
            name: "console.contents.edit",
            params: { contentResourceId: contentResourceId },
          });
        }
      },
      goToContents: () => {
        if (backRouter.includes("/console/ent/contents")) {
          goToEntContentsPath("contents");
        } else {
          router.push({
            name: "console.contents",
          });
        }
      },
    };

    return { state, actions, contentResourceId, moment, Helper };
  },
};
</script>

<style src="./style.css" scoped></style>
