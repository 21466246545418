
import { defineComponent } from "vue";
import BaseModal from "@/components/common/BaseModal.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default defineComponent({
  name: "SuccessCommentReportModal",
  components: { ButtonBasic, BaseModal },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const actions = {
      closeModal: () => {
        emit("closeModal");
      },
    };

    return { actions };
  },
});
