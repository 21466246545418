<template>
  <button class="btn-like" @click="actions.toggleLike()">
    <good-icon :fill-color="isLike ? '#8E1EFF' : '#0D0D10'"></good-icon>
    <span
      :class="isLike ? 'text-purple-50' : 'text-default'"
      class="sub-text-s3"
    >
      {{ count }}
    </span>
  </button>
</template>

<script>
import { useStore } from "vuex";
import { capitalize, upperCase } from "lodash/string";
import GoodIcon from "../../../../components/console/icons/GoodIcon";

export default {
  name: "ButtonLike",
  components: { GoodIcon },
  props: {
    isLike: {
      type: Boolean,
      required: true,
    },
    model: {
      type: String,
      required: true,
      validator(value) {
        return ["club", "content", "article", "comment"].includes(value);
      },
    },
    modelResourceId: {
      type: String,
      required: true,
    },
    parentModel: {
      type: String,
      required: false,
      validator(value) {
        return ["club", "content", "article", "comment"].includes(value);
      },
    },
    count: {
      type: [String, Number],
      default: 0,
    },
    parentModelResourceId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();

    const actions = {
      toggleLike: () => {
        let actionName;
        let mutationName;

        if (props.parentModel) {
          actionName = `post${capitalize(props.parentModel)}${capitalize(
            props.model
          )}Like`;
          mutationName = `${props.model}s/UPDATE_${upperCase(
            props.parentModel
          )}_${upperCase(props.model)}_PREV_LIKE`;
        } else {
          actionName = `post${capitalize(props.model)}Like`;
          mutationName = `${props.model}s/UPDATE_${upperCase(
            props.model
          )}_PREV_LIKE`;
        }
        const params = {
          model: props.model,
          resourceId: props.modelResourceId,
          mode: "toggle",
        };

        if (props.parentModelResourceId) {
          store.commit(mutationName, {
            resourceId: props.modelResourceId,
            parentResourceId: props.parentModelResourceId,
          });
          params.parentResourceId = props.parentModelResourceId;
        } else {
          store.commit(mutationName, { resourceId: props.modelResourceId }); // 미리 UI업데이트 하기 위함.
        }
        store.dispatch(`${props.model}s/${actionName}`, params);
      },
    };

    return { actions };
  },
};
</script>
<style src="./style.css" scoped></style>
