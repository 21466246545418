<template>
  <div>
    <div v-if="!state.isEditMode">
      <div class="avatar-name">
        <avatar :avatar-img="comment.user.avatar" size="32"></avatar>

        <div class="info">
          <h4>
            {{ comment.user.name }}
          </h4>
          <span class="sub-text-s3 text-gray-second">
            {{ comment.createdAtHumans }}
          </span>
        </div>

        <div class="dropdown-btn">
          <button-dropdown distance="4">
            <template #button>
              <button-text>
                <template #icon>
                  <more-verti-icon fill-color="#0D0D10"></more-verti-icon>
                </template>
              </button-text>
            </template>
            <template #dropdownList>
              <ul>
                <li v-if="state.myComment">
                  <button-dropdown-item
                    text="수정"
                    @click="actions.toggleEditMode()"
                  ></button-dropdown-item>
                </li>
                <li v-if="!state.myComment">
                  <button-dropdown-item
                    text="신고"
                    @click="actions.showReportCommentModal()"
                  ></button-dropdown-item>
                </li>
                <li v-if="state.myComment">
                  <button-dropdown-item
                    text="삭제"
                    @click="actions.deleteComment()"
                  ></button-dropdown-item>
                </li>
              </ul>
            </template>
          </button-dropdown>
        </div>
      </div>
      <div class="comment-content">
        <p ref="commentCommentEl" class="sub-text-s2">
          {{ comment.content }}
        </p>
        <div v-if="state.isSkipComment">
          <button-text
            text="자세히 보기"
            @click="actions.seeComment()"
          ></button-text>
        </div>

        <button-like
          model="comment"
          icon-position="left"
          :parent-model="childParentModel"
          :parent-model-resource-id="parentModelResourceId"
          :is-like="comment.isLiked"
          :model-resource-id="comment.resourceId"
          :count="comment.likersCount"
        ></button-like>
      </div>
    </div>
    <input-comment
      v-if="state.isEditMode"
      :text="comment.content"
      @action="
        (text) => {
          actions.putComment(text);
        }
      "
      @cancel="state.isEditMode = false"
    ></input-comment>
    <teleport :disabled="true">
      <report-comment-modal
        v-if="state.showCommentReportModal"
        model="comments"
        :model-resource-id="comment.resourceId"
        @hideModal="actions.closeReportCommentModal()"
        @reportSuccess="
          [actions.successCommentReport(), actions.closeReportCommentModal()]
        "
      ></report-comment-modal>
      <success-comment-report-modal
        v-if="state.showSuccessCommentReportModal"
        @closeModal="actions.closeSuccessCommentReportModal()"
      ></success-comment-report-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { capitalize } from "lodash/string";
import ApiService from "@/api";
import swal from "@/helper/swal";
import ButtonDropdown from "../ButtonDropdown/ButtonDropdown";
import Avatar from "../../../../components/console/avatars/Avatar";
import InputComment from "../InputComment/InputComment";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ButtonDropdownItem from "@/components/console/buttons/ButtonDropdownItem.vue";
import ButtonLike from "../ButtonLike/ButtonLike";
import ReportCommentModal from "@/pages/console/ContentShow/ReportCommentModal/ReportCommentModal.vue";
import SuccessCommentReportModal from "@/pages/console/ContentShow/SuccessCommentReportModal/SuccessCommentReportModal.vue";

export default {
  name: "CommentComment",
  components: {
    SuccessCommentReportModal,
    ReportCommentModal,
    ButtonDropdownItem,
    MoreVertiIcon,
    ButtonText,
    InputComment,
    Avatar,
    ButtonDropdown,
    ButtonLike,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    parentModel: {
      type: String,
      required: true,
      validator(value) {
        return ["content", "article", "comment"].includes(value);
      },
    },
    parentModelResourceId: {
      type: String,
      required: true,
    },
    childParentModel: {
      type: String,
      required: true,
      validator(value) {
        return ["comment"].includes(value);
      },
    },
  },
  setup(props) {
    const store = useStore();
    const commentCommentEl = ref(null);

    const state = reactive({
      commentCommentEl: null,
      user: computed(() => {
        return store.state.auth.user;
      }),
      myComment: computed(() => {
        return props.comment.user.resourceId === state.user.resourceId;
      }),
      isEditMode: false,
      isSkipComment: false,
      editingCommentResourceId: "",
      showCommentReportModal: false,
      showSuccessCommentReportModal: false,
    });

    onMounted(() => {
      actions.skipComment();
    });

    const actions = {
      postComment: (text) => {
        const actionName = `post${capitalize(props.model)}CommentComment`;

        const params = {
          resourceId: props.comment.resourceId,
          content: text,
          model: `comments`,
        };
        store.dispatch(`${props.model}/${actionName}`, params).then(() => {
          swal.createCompleteToast();
        });
      },
      deleteComment: () => {
        const actionName = `delete${capitalize(
          props.parentModel
        )}CommentComment`;

        store
          .dispatch(`comments/${actionName}`, {
            parentCommentResourceId: props.parentModelResourceId,
            commentResourceId: props.comment.resourceId,
          })
          .then(() => {
            swal.deleteCompleteToast();
          });
      },
      toggleEditMode: () => {
        state.isEditMode = true;
        state.showCommentComments = false;
      },
      postReport: async () => {
        const payload = {
          model: "comments",
          resourceId: props.comment.resourceId,
          reason: "신고를 해봅시다.",
        };
        await ApiService.postReport(payload).then((res) => {
          const result = res.data;
          if (result.success) {
            swal.successToast("신고되었습니다.");
          } else {
            swal.errorToast(result.message);
          }
        });
      },
      skipComment: () => {
        state.commentCommentEl = commentCommentEl.value;
        let compStyles = window.getComputedStyle(state.commentCommentEl);
        let height = compStyles.getPropertyValue("height").split("px")[0];
        if (height < 64) {
          return;
        }
        state.isSkipComment = true;
        commentCommentEl.value.className = "comment-skip";
      },
      seeComment: () => {
        commentCommentEl.value.classList.remove("comment-skip");
        state.isSkipComment = !state.isSkipComment;
      },
      thumbsUp: () => {
        let payload = {
          model: "comment",
          resourceId: props.comment.resourceId,
          mode: "toggle",
        };
        ApiService.postLike(payload);
      },
      putComment: (text) => {
        const actionName = `put${capitalize(props.parentModel)}CommentComment`;

        const payload = {
          parentCommentResourceId: props.parentModelResourceId,
          commentResourceId: props.comment.resourceId,
          content: text,
        };

        store.dispatch(`comments/${actionName}`, payload).then(() => {
          state.isEditMode = false;
        });
      },
      successCommentReport: () => {
        state.showSuccessCommentReportModal = true;
      },
      showReportCommentModal: () => {
        state.showCommentReportModal = true;
      },
      closeReportCommentModal: () => {
        state.showCommentReportModal = false;
      },
      closeSuccessCommentReportModal: () => {
        state.showSuccessCommentReportModal = false;
      },
    };

    return { state, actions, commentCommentEl };
  },
};
</script>

<style src="./style.css" scoped></style>
