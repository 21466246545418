<template>
  <div class="input-comment">
    <avatar :avatar-img="state.user.avatar" :size="size"></avatar>

    <div class="form-area">
      <textarea
        v-model="state.text"
        class="comment-textarea"
        :class="`sub-title-${textSize}`"
        :placeholder="placeholder"
        :style="{ height: '80px', resize: 'none' }"
        @keypress.enter.prevent="actions.submitAction()"
      ></textarea>

      <div class="button-wrapper">
        <button-text
          text="취소"
          padding="14px 8px"
          color="#A8A8A8"
          text-size="s2"
          @click="actions.cancel()"
        ></button-text>

        <button-text
          text="등록"
          padding="14px 8px"
          color="#7F00FF"
          text-size="s2"
          :disabled="state.text.length === 0"
          @click="actions.submitAction()"
        ></button-text>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import Avatar from "../../../../components/console/avatars/Avatar";
import ButtonText from "@/components/console/buttons/ButtonText.vue";

export default {
  name: "InputComment",
  components: { ButtonText, Avatar },
  props: {
    placeholder: {
      type: String,
      default: "댓글을 입력해주세요.",
    },
    text: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 32,
    },
    textSize: {
      type: String,
      default: "s1",
      validator(value) {
        return ["s1", "s2", "s3"].includes(value);
      },
    },
  },
  emits: ["action", "cancel"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      text: props.text,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
    });

    const actions = {
      submitAction: () => {
        if (state.text) {
          emit("action", state.text);
          state.text = "";
        }
      },
      cancel: () => {
        emit("cancel");
        state.text = "";
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
