<template>
  <template v-for="(data, index) in state.paragraph" :key="`t-${index}`">
    {{ data }}<span v-if="space">&nbsp;</span
    ><br v-if="state.paragraph.length - 1 !== index" :class="blockClass" />
  </template>
</template>

<script>
import { reactive, computed } from "vue";
export default {
  components: {},
  props: {
    data: String,
    blockClass: {
      type: String,
      default: "",
    },
    space: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const state = reactive({
      paragraph: computed(() => {
        return props.data.split("\n");
      }),
    });

    return {
      state,
    };
  },
};
</script>
