<template>
  <div>
    <div class="comment-component">
      <div v-if="!state.isEditMode">
        <div class="writer">
          <avatar :avatar-img="comment.user.avatar" size="32"></avatar>
          <div class="writer-info">
            <h4>
              {{ comment.user.name }}
            </h4>
            <span class="sub-text-s3 text-gray-second">
              {{ comment.createdAtHumans }}
            </span>
          </div>
          <div class="dropdown-btn">
            <button-dropdown distance="8">
              <template #button>
                <button-text>
                  <template #icon>
                    <more-verti-icon fill-color="#0D0D10"></more-verti-icon>
                  </template>
                </button-text>
              </template>
              <template #dropdownList>
                <ul class="dropdown-list">
                  <li v-if="state.myComment">
                    <button-dropdown-item
                      text="수정"
                      @click="actions.toggleEditMode()"
                    ></button-dropdown-item>
                  </li>
                  <li v-if="!state.myComment">
                    <button-dropdown-item
                      text="신고"
                      @click="actions.showReportCommentModal()"
                    ></button-dropdown-item>
                  </li>
                  <li v-if="state.myComment">
                    <button-dropdown-item
                      text="삭제"
                      @click="actions.deleteComment()"
                    ></button-dropdown-item>
                  </li>
                </ul>
              </template>
            </button-dropdown>
          </div>
        </div>

        <div class="content-view">
          <p ref="commentEl" class="sub-text-s2">
            {{ comment.content }}
          </p>
          <div v-if="state.isSkipComment">
            <button-text
              text="자세히 보기"
              padding="0"
              color="#818287 "
              @action="actions.seeComment()"
            ></button-text>
          </div>

          <div class="react-btns">
            <button-like
              model="comment"
              icon-position="left"
              :parent-model="parentModel"
              :is-like="comment.isLiked"
              :model-resource-id="comment.resourceId"
              :count="comment.likersCount"
            ></button-like>

            <button-text
              :is-icon="true"
              padding="0px 4px"
              icon-position="front"
              :text="
                comment.comments ? comment.comments.length.toString() : '0'
              "
              @click="state.showCommentComments = !state.showCommentComments"
            >
              <template #icon>
                <comment-icon></comment-icon>
              </template>
            </button-text>
          </div>
        </div>
      </div>
      <input-comment
        v-if="state.isEditMode"
        :text="comment.content"
        text-size="s2"
        @action="
          (text) => {
            actions.putComment(text);
          }
        "
        @cancel="state.isEditMode = false"
      ></input-comment>
    </div>

    <div v-if="state.showCommentComments" class="comment-create">
      <input-comment
        class="comment-input"
        text-size="s2"
        @action="
          (text) => {
            actions.postComment(text);
          }
        "
      />
      <ul v-if="state.commentComments" class="sub-comment__list">
        <li
          v-for="commentcomment in state.commentComments"
          :key="commentcomment.resourceId"
        >
          <comment-comment
            :comment="commentcomment"
            :parent-model="parentModel"
            :child-parent-model="childParentModel"
            :parent-model-resource-id="comment.resourceId"
          ></comment-comment>
        </li>
      </ul>
      <div v-if="state.isMoreCommentCommentsBtn">
        <button-text
          icon-position="back"
          :is-icon="true"
          text="답글 더보기"
          @click="state.isMoreCommentComments = !state.isMoreCommentComments"
        >
          <template #icon>
            <plus-icon></plus-icon>
          </template>
        </button-text>
      </div>
    </div>
    <teleport :disabled="true">
      <report-comment-modal
        v-if="state.showCommentReportModal"
        model="comments"
        :model-resource-id="comment.resourceId"
        @hideModal="actions.closeReportCommentModal()"
        @reportSuccess="
          [actions.successCommentReport(), actions.closeReportCommentModal()]
        "
      ></report-comment-modal>
      <success-comment-report-modal
        v-if="state.showSuccessCommentReportModal"
        @closeModal="actions.closeSuccessCommentReportModal()"
      ></success-comment-report-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { capitalize } from "lodash/string";
import ApiService from "@/api";
import swal from "@/helper/swal";
import InputComment from "../InputComment/InputComment";
import Avatar from "../../../../components/console/avatars/Avatar";
import CommentComment from "../CommentComment/CommentComment";
import ButtonDropdown from "@/components/console/buttons/ButtonDropdown.vue";
import ButtonLike from "../ButtonLike/ButtonLike";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ButtonDropdownItem from "@/components/console/buttons/ButtonDropdownItem.vue";
import CommentIcon from "@/components/console/icons/CommentIcon.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import ReportCommentModal from "@/pages/console/ContentShow/ReportCommentModal/ReportCommentModal.vue";
import SuccessCommentReportModal from "@/pages/console/ContentShow/SuccessCommentReportModal/SuccessCommentReportModal.vue";

export default {
  name: "CommentComponent",
  components: {
    SuccessCommentReportModal,
    ReportCommentModal,
    PlusIcon,
    CommentIcon,
    ButtonDropdownItem,
    MoreVertiIcon,
    ButtonText,
    ButtonLike,
    ButtonDropdown,
    CommentComment,
    Avatar,
    InputComment,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    parentModel: {
      type: String,
      required: true,
      validator(value) {
        return ["content", "article", "comment"].includes(value);
      },
    },
    childParentModel: {
      type: String,
      required: true,
      validator(value) {
        return ["comment"].includes(value);
      },
    },
  },
  setup(props) {
    const store = useStore();
    const commentEl = ref(null);

    const state = reactive({
      commentEl: null,
      isMoreCommentComments: false,
      isMoreCommentCommentsBtn: computed(() => {
        if (!props.comment.comments) {
          return false;
        } else {
          return (
            props.comment.comments.length > 5 && !state.isMoreCommentComments
          );
        }
      }),
      commentComments: computed(() => {
        if (!props.comment.comments) {
          return false;
        }
        let commentComments = props.comment.comments.slice();
        if (commentComments.length > 5 && !state.isMoreCommentComments) {
          return commentComments.splice(0, 5);
        } else {
          return commentComments;
        }
      }),
      showCommentComments: false,
      user: computed(() => {
        return store.state.auth.user;
      }),
      isEditMode: false,
      myComment: computed(() => {
        return props.comment.user.resourceId === state.user.resourceId;
      }),
      isSkipComment: false,
      showCommentReportModal: false,
      showSuccessCommentReportModal: false,
    });

    onMounted(() => {
      actions.skipComment();
    });

    const actions = {
      postComment: (text) => {
        const actionName = `post${capitalize(props.parentModel)}CommentComment`;

        const params = {
          resourceId: props.comment.resourceId,
          content: text,
          model: `comments`,
        };
        store.dispatch(`comments/${actionName}`, params);
      },
      putComment: (text) => {
        const actionName = `put${capitalize(props.parentModel)}Comment`;
        const payload = {
          commentResourceId: props.comment.resourceId,
          content: text,
        };
        store.dispatch(`comments/${actionName}`, payload).then(() => {
          state.isEditMode = false;
        });
      },
      deleteComment: () => {
        const actionName = `delete${capitalize(props.parentModel)}Comment`;

        store
          .dispatch(`comments/${actionName}`, {
            commentResourceId: props.comment.resourceId,
          })
          .then(() => {
            swal.deleteCompleteToast();
          });
      },
      toggleEditMode: () => {
        state.isEditMode = true;
        state.showCommentComments = false;
      },
      postReport: async () => {
        const payload = {
          model: "comments",
          resourceId: props.comment.resourceId,
          reason: "신고를 해봅시다.",
        };
        await ApiService.postReport(payload).then((response) => {
          const result = response.data;
          if (result.success) {
            swal.successToast("신고되었습니다.");
          } else {
            swal.errorToast(result.message);
          }
        });
      },
      skipComment: () => {
        state.commentEl = commentEl.value;

        let compStyles = window.getComputedStyle(state.commentEl);
        let height = compStyles.getPropertyValue("height").split("px")[0];
        if (height < 64) {
          return;
        }
        state.isSkipComment = true;
        state.commentEl.className = "comment-skip";
      },
      seeComment: () => {
        state.commentEl.classList.remove("comment-skip");
        state.isSkipComment = !state.isSkipComment;
      },
      successCommentReport: () => {
        state.showSuccessCommentReportModal = true;
      },
      showReportCommentModal: () => {
        state.showCommentReportModal = true;
      },
      closeReportCommentModal: () => {
        state.showCommentReportModal = false;
      },
      closeSuccessCommentReportModal: () => {
        state.showSuccessCommentReportModal = false;
      },
    };

    return { state, actions, commentEl };
  },
};
</script>

<style src="./style.css" scoped></style>
