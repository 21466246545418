<template>
  <icon-base
    icon-name="good-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.27069 3H9.5C9.77614 3 10 3.22386 10 3.5V6.5C10 7.32843 10.6716 8 11.5 8H12.5C12.7761 8 13 8.22386 13 8.5V11C13 12.1046 12.1046 13 11 13H7C6.44772 13 6 12.5523 6 12V8.7241C6 8.49016 6.08202 8.26363 6.23178 8.08392L7.92103 6.05681C8.26206 5.64757 8.48798 5.15491 8.57555 4.62945L8.77749 3.4178C8.81768 3.17671 9.02627 3 9.27069 3ZM5.6402 7.23176L7.15281 5.41662C7.38017 5.1438 7.53077 4.81536 7.58916 4.46505L7.7911 3.2534C7.91165 2.53012 8.53743 2 9.27069 2H9.5C10.3284 2 11 2.67157 11 3.5V6.5C11 6.77614 11.2239 7 11.5 7H12.5C13.3284 7 14 7.67157 14 8.5V11C14 12.6569 12.6569 14 11 14H7C6.54975 14 6.13424 13.8512 5.79997 13.6001C5.61753 13.8429 5.32711 14 5 14H3C2.44772 14 2 13.5523 2 13V8C2 7.44772 2.44772 7 3 7H5C5.24354 7 5.46675 7.08706 5.6402 7.23176ZM5 12V8.7241V8H3V13H5V12Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>

    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.27069 2C8.53743 2 7.91165 2.53012 7.7911 3.2534L7.58916 4.46505C7.53077 4.81536 7.38017 5.1438 7.15281 5.41662L5.6402 7.23176C5.46675 7.08706 5.24354 7 5 7H3C2.44772 7 2 7.44772 2 8V13C2 13.5523 2.44772 14 3 14H5C5.32711 14 5.61753 13.8429 5.79997 13.6001C6.13424 13.8512 6.54975 14 7 14H11C12.6569 14 14 12.6569 14 11V8.5C14 7.67157 13.3284 7 12.5 7H11.5C11.2239 7 11 6.77614 11 6.5V3.5C11 2.67157 10.3284 2 9.5 2H9.27069ZM6 8.75C6 8.47386 5.77614 8.25 5.5 8.25C5.22386 8.25 5 8.47386 5 8.75L5 12.25C5 12.5261 5.22386 12.75 5.5 12.75C5.77614 12.75 6 12.5261 6 12.25L6 8.75Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "GoodIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#818287",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
