import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_basic = _resolveComponent("button-basic")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    class: "show-success-report-modal",
    title: "신고가 접수되었어요",
    "sub-title": "신고된 게시물은 확인 후 부적절한 게시물로 판별된 경우 삭제됩니다.",
    onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.actions.closeModal()))
  }, {
    modalBody: _withCtx(() => [
      _createVNode(_component_button_basic, {
        class: "close-btn",
        "text-size": "s1",
        padding: "14px",
        text: "확인",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actions.closeModal()))
      })
    ]),
    _: 1
  }))
}